// REACT
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"

import { AddressTypeEnum } from "../../../../../../constants/quotationsConstants"
import { billDutyToListCC, billDutyToListNoCC, businessRelationshipList, importExportList } from "../../../../../../utils/international-options"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { updateBroker, updateBuyer, updateCommercialInvoice, updateInternationalOptions, updateShippingOptions } from "../../../../../../reducers/quotationsReducer"
import { useGetAddressBookQuery } from "../../../../../address-book/slice"
import { isCommercialInvoiceComplete } from "../../../../utils"

function RateRequestInternationalOptions({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation(["rateRequest"])
  const customTheme = useTheme()

  const dispatch = useDispatch()
  const currentCompany = useSelector((state) => state.user?.company)
  
  const { broker, commercialInvoice, internationalOptions, shippingOptions } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")
  const [documentsOnlyChecked, setDocumentsOnlyChecked] = useState(!!internationalOptions?.documentsOnly || false)
  const [billDutyToChecked, setBillDutyToChecked] = useState(!!internationalOptions?.billDutyTo || true)
  const [dutyAccountNumberChecked, setDutyAccountNumberChecked] = useState(!!internationalOptions?.dutyAccountNumber)
  const [recipientTaxIdChecked, setRecipientTaxIdChecked] = useState(!!internationalOptions?.recipientTaxId)
  const [senderIsProducerChecked, setSenderIsProducerChecked] = useState(!!internationalOptions?.senderIsProducer || false)

  const [businessRelationshipChecked, setBusinessRelationshipChecked] = useState(!!internationalOptions?.businessRelationship)
  const [exportTypeChecked, setExportTypeChecked] = useState(!!internationalOptions?.exportType)
  const [brokerChecked, setBrokerChecked] = useState(!!broker)
  const [brokerList, setBrokerList] = useState([])
  const [selectedBroker, setSelectedBroker] = useState(broker || undefined)

  const { data: addressBookItems } = useGetAddressBookQuery()
  const currentUser = useSelector((state) => state.user)

  const newBroker = { id: "newBroker", companyName: "" }

  const billDutyToList =  (currentCompany?.featureFlags?.isBillingSenderAllowed === true) 
    || currentCompany?.paymentMethods?.length > 0 ?
    billDutyToListCC : billDutyToListNoCC

  const formik = useFormik({
    initialValues: {
      documentsOnly: internationalOptions?.documentsOnly || undefined,
      declaredValue: internationalOptions?.declaredValue || {
        amount: undefined,
        currency: currentUser.billing.currency || "CAD",
      },
      billDutyTo: internationalOptions?.billDutyTo || undefined,
      dutyAccountNumber: internationalOptions?.dutyAccountNumber || undefined,
      recipientTaxId: internationalOptions?.recipientTaxId || undefined,
      senderIsProducer: internationalOptions?.senderIsProducer || undefined,
      businessRelationship: internationalOptions?.businessRelationship || undefined,
      exportType: internationalOptions?.exportType || undefined,
    },
    validate: (formValues) => {
      const errors = {}

      if (recipientTaxIdChecked && !formValues.recipientTaxId) {
        errors.recipientTaxId = true
      }

      if (billDutyToChecked && !formValues.billDutyTo) {
        errors.billDutyTo = true
      }

      if (dutyAccountNumberChecked && !formValues.dutyAccountNumber) {
        errors.dutyAccountNumber = true
      } else if ((/\s/).test(formValues.dutyAccountNumber)) {
          errors.dutyAccountNumberHasWhiteSpace = true
      }

      if (businessRelationshipChecked && !formValues.businessRelationship) {
        errors.businessRelationship = true
      }

      if (exportTypeChecked && !formValues.exportType) {
        errors.exportType = true
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      if (brokerChecked) formValues = { ...formValues, brokerRequired: true }
      if (isCommercialInvoiceComplete(commercialInvoice)) {
        formValues = {
          ...formValues,
          ...{
            declaredValue: !documentsOnlyChecked
              ? {
                  amount: commercialInvoice.customsValue,
                  currency: commercialInvoice.currency,
                }
              : undefined,
          },
        }
      }
      dispatch(updateInternationalOptions(formValues))
      if (brokerChecked && selectedBroker?.id !== "newBroker") {
        dispatch(updateBroker(selectedBroker))
      } else {
        dispatch(updateBroker(undefined))
      }
      if (documentsOnlyChecked) {
        dispatch(updateShippingOptions({ ...shippingOptions, insurance: undefined }))
        dispatch(updateBuyer(undefined))
        dispatch(updateCommercialInvoice(undefined))
      }
      if (action === "continue") {
        if (brokerChecked) {
          wizardProps.goToNamedStep("broker")
          return
        }
        if (!documentsOnlyChecked) {
          wizardProps.goToNamedStep("commercial-invoice")
          return
        }
        return
      }
      if (action === "saveAndClose") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (addressBookItems && currentUser && !selectedBroker) {
      let defaultBroker = currentUser.defaultBrokerId && addressBookItems.find((addressBook) => addressBook.id === currentUser.defaultBrokerId)
      if (!defaultBroker) defaultBroker = newBroker
      setSelectedBroker(defaultBroker)
    }
  }, [addressBookItems, currentUser])

  useEffect(() => {
    let brokers = [newBroker]
    if (addressBookItems) {
      brokers = brokers.concat(addressBookItems.filter((element) => element.type === AddressTypeEnum.Broker))
    }
    setBrokerList(brokers)
  }, [addressBookItems])

  useEffect(() => {
    if (broker) setSelectedBroker(broker)
  }, [broker])

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  const handleBillDutyToChange = (event) => {
    if (event?.target?.value === "thirdParty") {
      setDutyAccountNumberChecked(true)
    } else {
      setDutyAccountNumberChecked(false)
      formik.setFieldValue("dutyAccountNumber", undefined)
    }
    formik.handleChange(event)
  }

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.internationalOptions.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Card
            sx={(theme) => ({
              mb: theme.spacing(2),
            })}
          >
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  py: customTheme.utils.pxToThemeSpacing(4),
                },
              }}
            >
              <Box component="div">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event, value) => {
                        setDocumentsOnlyChecked(value)
                        formik.setFieldValue("documentsOnly", value || undefined)
                        if (!value) {
                          setBillDutyToChecked(true)
                          formik.setFieldValue("billDutyTo", undefined)
                        } else {
                          formik.setFieldValue("declaredValue", undefined)
                          setBillDutyToChecked(false)
                          formik.setFieldValue("billDutyTo", undefined)
                          setDutyAccountNumberChecked(false)
                          formik.setFieldValue("dutyAccountNumber", undefined)
                          setRecipientTaxIdChecked(false)
                          formik.setFieldValue("recipientTaxId", undefined)
                          setSenderIsProducerChecked(false)
                          formik.setFieldValue("senderIsProducer", undefined)
                          setBusinessRelationshipChecked(false)
                          formik.setFieldValue("businessRelationship", undefined)
                          setExportTypeChecked(false)
                          formik.setFieldValue("exportType", undefined)
                          setBrokerChecked(false)
                          setSelectedBroker(undefined)
                        }
                      }}
                      name="documentsOnly"
                      checked={documentsOnlyChecked}
                    />
                  }
                  label={t("drawer.internationalOptions.fields.documentsOnly.label", { ns: "rateRequest" })}
                />
              </Box>
            </CardContentNoPaddingBottom>
          </Card>
          {!documentsOnlyChecked && (
            <Card
              sx={(theme) => ({
                mb: theme.spacing(2),
              })}
            >
              <CardContentNoPaddingBottom
                sx={{
                  "&:last-child": {
                    pt: customTheme.utils.pxToThemeSpacing(6),
                    pb: customTheme.utils.pxToThemeSpacing(2),
                  },
                }}
              >
                <Box component="div" mx={customTheme.spacing(0)} mb={customTheme.utils.pxToThemeSpacing(6)}>
                  <Box component="div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="billDutyToRequired"
                          onChange={(event, value) => {
                            setBillDutyToChecked(value)
                          }}
                          checked={billDutyToChecked}
                        />
                      }
                      value={billDutyToChecked}
                      disabled
                      checked
                      label={t("drawer.internationalOptions.fields.billDutyToCheckbox.label", { ns: "rateRequest" })}
                    />
                  </Box>
                  {billDutyToChecked && (
                    <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                      <FormControl fullWidth error={formik.touched.billDutyTo && formik.errors.billDutyTo}>
                        <InputLabel />
                        <Select name="billDutyTo" onChange={handleBillDutyToChange} value={formik.values.billDutyTo || ""} size="small" defaultValue="" error={formik.touched.billDutyTo && formik.errors.billDutyTo}>
                          {billDutyToList.map((billDutyTo, index) => (
                            <MenuItem key={billDutyTo.value.concat(index)} value={billDutyTo.value}>
                              {t(`drawer.internationalOptions.fields.billDutyTo.options.${billDutyTo.label}`, { ns: "rateRequest" })}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Box component="div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dutyAccountNumberRequired"
                          onChange={(event, value) => {
                            setDutyAccountNumberChecked(value)
                          }}
                          checked={dutyAccountNumberChecked}
                        />
                      }
                      value={dutyAccountNumberChecked}
                      label={t("drawer.internationalOptions.fields.dutyAccountNumberCheckbox.label", { ns: "rateRequest" })}
                    />
                  </Box>
                  {dutyAccountNumberChecked && (
                    <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                      <TextField
                        name="dutyAccountNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dutyAccountNumber || ""}
                        type="numeric"
                        min="0"
                        label={t("drawer.internationalOptions.fields.dutyAccountNumber.label", { ns: "rateRequest" })}
                        variant="outlined"
                        fullWidth
                        error={(formik.touched.dutyAccountNumber && formik.errors.dutyAccountNumber) || formik.errors.dutyAccountNumber}
                        size="small"
                      />
                    </Box>
                  )}
                  <Box component="div" mt={customTheme.utils.pxToThemeSpacing(6)}>
                    <Box component="div">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="recipientTaxIdRequired"
                            onChange={(event, value) => {
                              setRecipientTaxIdChecked(value)
                            }}
                            checked={recipientTaxIdChecked}
                          />
                        }
                        value={recipientTaxIdChecked}
                        label={t("drawer.internationalOptions.fields.recipientTaxIdCheckbox.label", { ns: "rateRequest" })}
                      />
                    </Box>
                    {recipientTaxIdChecked && (
                      <Box component="div">
                        <TextField
                          name="recipientTaxId"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.recipientTaxId || ""}
                          type="numeric"
                          min="0"
                          label={t("drawer.internationalOptions.fields.recipientTaxIdValue.label", { ns: "rateRequest" })}
                          variant="outlined"
                          fullWidth
                          error={formik.touched.recipientTaxId && formik.errors.recipientTaxId}
                          size="small"
                          sx={(theme) => ({ my: theme.utils.pxToRem(6) })}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box component="div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="senderIsProducer"
                          onChange={(event, value) => {
                            setSenderIsProducerChecked(value)
                            formik.setFieldValue("senderIsProducer", value || undefined)
                          }}
                          checked={senderIsProducerChecked}
                        />
                      }
                      value={senderIsProducerChecked}
                      label={t("drawer.internationalOptions.fields.senderIsProducer.label", { ns: "rateRequest" })}
                    />
                  </Box>
                  <Box component="div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event, value) => {
                            setBusinessRelationshipChecked(value)
                          }}
                          checked={businessRelationshipChecked}
                        />
                      }
                      value={businessRelationshipChecked}
                      name="businessRelationshipRequired"
                      label={t("drawer.internationalOptions.fields.businessRelationship.label", { ns: "rateRequest" })}
                    />
                  </Box>
                  {businessRelationshipChecked && (
                    <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                      <FormControl fullWidth error={formik.touched.businessRelationship && formik.errors.businessRelationship}>
                        <InputLabel />
                        <Select name="businessRelationship" onChange={formik.handleChange} value={formik.values.businessRelationship || ""} size="small">
                          {businessRelationshipList.map((businessRelationship, index) => (
                            <MenuItem key={businessRelationship.value.concat(index)} value={businessRelationship.value}>
                              {t(`drawer.internationalOptions.fields.businessRelationship.options.${businessRelationship.label}`, { ns: "rateRequest" })}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Box component="div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="exportTypeRequired"
                          onChange={(event, value) => {
                            setExportTypeChecked(value)
                          }}
                          checked={exportTypeChecked}
                        />
                      }
                      value={formik.values.exportType}
                      label={t("drawer.internationalOptions.fields.exportType.label", { ns: "rateRequest" })}
                    />
                  </Box>
                  {exportTypeChecked && (
                    <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                      <FormControl fullWidth error={formik.touched.exportType && formik.errors.exportType}>
                        <InputLabel />
                        <Select name="exportType" onChange={formik.handleChange} value={formik.values.exportType || ""} size="small" defaultValue="">
                          {importExportList.map((importExport, index) => (
                            <MenuItem key={importExport.value.concat(index)} value={importExport.value}>
                              {t(`drawer.internationalOptions.fields.exportType.options.${importExport.label}`, { ns: "rateRequest" })}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Box component="div">
                    <FormControlLabel
                      control={<Checkbox checked={brokerChecked} onChange={() => setBrokerChecked(!brokerChecked)} />}
                      label={t("drawer.internationalOptions.fields.brokerRequired.label", { ns: "rateRequest" })}
                      size="small"
                      name="brokerRequired"
                    />
                    {brokerChecked && (
                      <Box component="div" my={customTheme.utils.pxToThemeSpacing(6)}>
                        <Autocomplete
                          value={brokerList?.find((element) => selectedBroker?.id === element.id) || null}
                          size="small"
                          disablePortal
                          onChange={(_, value) => {
                            setSelectedBroker(value)
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id
                          }}
                          name="selectedBroker"
                          onBlur={formik.handleBlur}
                          getOptionLabel={(option) => {
                            if (option.id === "newBroker") option.companyName = t("drawer.internationalOptions.fields.broker.newBroker")
                            return option.companyName
                          }}
                          renderOption={(renderOptionProps, option) => {
                            return (
                              <li {...renderOptionProps} key={option.id}>
                                {option.companyName}
                              </li>
                            )
                          }}
                          options={brokerList || []}
                          PaperComponent={AutoCompletePaper}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={selectedBroker?.id === "newBroker" ? t("drawer.internationalOptions.fields.broker.helperText", { ns: "rateRequest" }) : ""}
                              label={t("drawer.internationalOptions.fields.broker.label", { ns: "rateRequest" })}
                            />
                          )}
                          noOptionsText={t("drawer.internationalOptions.fields.broker.noBroker")}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </CardContentNoPaddingBottom>
            </Card>
          )}
        </form>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          wizardProps.currentStep === wizardProps.totalSteps || brokerChecked || !documentsOnlyChecked
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.internationalOptions.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.internationalOptions.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.internationalOptions.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
        }
        actions={wizardProps.currentStep === wizardProps.totalSteps || brokerChecked || !documentsOnlyChecked ? [handleSaveAndCloseClick, handleContinueClick] : [handleSaveAndCloseClick]}
        labels={[t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }), t("drawer.packages.actions.nextButton.label", { ns: "rateRequest" })]}
        variants={["outlined", "contained"]}
      />
    </SoeDrawerContainer>
  )
}

RateRequestInternationalOptions.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestInternationalOptions.defaultProps = {}

export default RateRequestInternationalOptions
