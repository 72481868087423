const AppConfig = {
  app: {
    version: "1.1.1",
    environment: "dev",
    nodeEnv: "development",
    addressAutoCompleteTimeout: "350",
  },
  aws: {
    cognito: {
      userPoolId: "us-east-1_dSg9rDdRV",
      clientId: "7ve4m5jb7r0as75j8qgphgl6r5",
      hostedUiUrl: "https://devsaveonexpress.auth.us-east-1.amazoncognito.com",
    },
  },
  externalLinks: {
    harmonizedSystemSearchUrl: "https://www.tariffinder.ca/en/getStarted",
  },
  restApi: {
    apiUrl: "https://api.dev.saveonexpress.ca",
  },
  websocket: {
    url: "wss://wss.dev.saveonexpress.ca",
    keepaliveDelay: "540000",
  },
  stripe: {
    publicKey: "pk_test_51KgDvOBCsctpTZ5ifuaGxoXkiCC7iTK2h8sKLYlsXtLnj468nzFVUP4jcEVFpPwQSeLsH5JErUq0fTWlT0ZIadDZ00L2GCXso3",
  },
  features: {
    showDiscountAmount: "false" === "true",
    showAddressValidation: "false" === "true",
    showOrderTypeColumn: "false" === "true",
  },
  logRocket: {
    id: process.env.REACT_APP_LOGROCKET_ID,
  },
  recaptcha: {
    siteId: "6Lf0UgYpAAAAACtZKICElZZK2FCES0TmgJQPoSTd",
  },
}

export default AppConfig
